// i18next-extract-mark-ns-start products-wave-combustion

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveCombustionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							WAVE contains advanced combustion models for diesel and
							spark ignition (SI) engines and includes secondary
							models specifically for the study of direct injection,
							SI turbulent flame propagation, engine-out emissions and
							knock. In addition to in-cylinder models, WAVE also
							includes a suite of exhaust aftertreatment models. These
							enable detailed chemical kinetic simulation of advanced
							emission control technologies to be carried out. WAVE
							allows the combustion of multiple fuels according to a
							prescribed burn rate where the combustion can be
							calculated using a semi-automated heat release analysis
							process.
						</p>
						<ul>
							<li>Definition:
								<ul>
									<li>Complex fuel material definition with an extensive database of hydro-carbons and surrogates provided
										for most widely used applications.</li>
									<li>Complete control over injection strategies with a wide variety of multi-pulse actuatable injectors</li>
									<li>Cylinder pressure heat release analysis creating combustion model parameters from measured data</li>
								</ul>
							</li>
							<li>Models:
								<ul>
									<li>Compression ignition analysis including:
										<ul>
											<li>Ignition delay modelling</li>
											<li>Diesel Wiebe semi-predictive combustion sub-model (which includes pilot injection)</li>
											<li>Diesel Predictive combustion model</li>
											<li>Diesel jet predictive combustion sub-model (Hiroyasu)</li>
										</ul>
									</li>
									<li>Spark ignition analysis including:
										<ul>
											<li>Multi-Wiebe combustion sub-model</li>
											<li>SI turbulent flame predictive combustion sub-model</li>
											<li>Knock sub-model</li>
										</ul>
									</li>
									<li>Experimental heat release profiles</li>
									<li>User code combustion sub-model</li>
									<li>Predictive emissions sub-models</li>
									<li>Zeldovich SI NOx model</li>
									<li>Diesel NOx mode</li>
									<li>CO model</li>
									<li>HC emissions model</li>
								</ul>
							</li>
							<li>Analyse:
								<ul>
									<li>Detailed post-processing of combustion properties through summary variables and
										dedicated sensors including analysis of turbulence and predictive combustion models</li>
								</ul>
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveCombustionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-combustion", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
}
`;

